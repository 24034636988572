import React, { useEffect, useState } from 'react';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { findFolderById, findMatch } from '../services/shared'; 
import { useSearchContext } from '../context/SearchContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { isMobile } from 'react-device-detect';
import MatchPrevIcon from '../assets/MatchPrevIcon.svg';
import MatchNextIcon from '../assets/MatchNextIcon.svg';
import { showMessage } from '../languages/_showmessages';

const MatchsBox = () => {
    const { Search, setSearch } = useSearchContext();
    const { Folders } = useFoldersContext();
    const { Page, setPage } = usePageContext();
    const { PageLoad, setPageLoad } = usePageLoadContext();
    const { Folder, setFolder } = useFolderContext();
    const [ currentIndex, setCurrentIndex ] = useState<number>(-1);
    const [ maxIndex, setMaxIndex ] = useState<number>(0);
    const { setMessageBox } = useMessageBoxContext();

    useEffect(() => {
      setMaxIndex(Search?.pagesCount ?? 0);
      setCurrentIndex(-1); 
      move(0);
    }, [Search]);
   
    useEffect(() => {
      if (!PageLoad) return;

      if (Search?.matchs && Folder && Page) {
        setCurrentIndex(findMatch(Search.matchs, Folder.Id, Page) ?? currentIndex);
      }
    }, [PageLoad]);

    const move = (index: number) => {
      if (Folders === undefined || Folders === null) return;

      if (index < 0) {
        setMessageBox("Primeira ocorrência");  
        return;
      }

      if (index > maxIndex - 1 && maxIndex !==0) {
        setMessageBox("Última ocorrência");  
        return;
      }

      setCurrentIndex(index); 
 
      var match = Search?.matchs[index];   
      if (match === undefined) {
        setSearch(null);
        return;
      }

      const folder = findFolderById(Folders, match.folderID);  
      if (folder !== null ) {
        setFolder(folder);
        setPage(match.page);

        // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
        setTimeout(() => {
          setPageLoad(true);
        }, 100);
      };
    };    

    return (
      <>
        {isMobile ? 
          <>
            <div className='matchsbox-mobile'>          
              <div className='matchstitle-mobile'>
                <small>{showMessage("ocorrencias")}</small>
              </div>
              <img src={MatchPrevIcon} className='matchprev-mobile' alt="matchprevious" onClick={() => { move(currentIndex - 1)}}/>
              <b className='matchsnumber-mobile'>{currentIndex + 1}/{maxIndex}</b>
              <img src={MatchNextIcon} className='matchnext-mobile' alt="matchnext" onClick={() => { move(currentIndex + 1)}}/>
            </div>    
          </>
        :
          <>
            <div className='matchsbox'>          
              <div className='matchstitle'>
                <small>{showMessage("ocorrencias")}</small>
              </div>
              <img src={MatchPrevIcon} className='matchprev' alt="matchprevious" onClick={() => { move(currentIndex - 1)}}/>
              <b className='matchsnumber'>{currentIndex + 1}/{maxIndex}</b>
              <img src={MatchNextIcon} className='matchnext' alt="matchnext" onClick={() => { move(currentIndex + 1)}}/>
            </div>    
          </>
        }
      </>
    );
  };

  export default MatchsBox;