import axios from 'axios';
import { BaseModel } from '../models/baseModel';
import { SearchModel } from '../models/searchModel';
import { LibrariesListModel } from '../models/librariesModel';
import { getDocSearchAPI, getShowLogs } from '../services/appSettings';

const baseUrl = getDocSearchAPI();
if (!baseUrl) {
  console.error('Environment variable REACT_APP_DOCSEARCHAPI is not set');
  throw new Error("DocReaderAPI Url not set");
} 

export const searchApi = async (library: string, textSearch : string, token: string) : Promise<SearchModel> => { 
  const url = `${baseUrl}/search/${library}/${textSearch}?matchslist=2`;
  const headers = token !== "" ? {headers: {Authorization: `Bearer ${token}`}} : {headers: {Authorization: `none`}};

  return axios.get(url, headers)
    .then(response => {                  
      var basedata = response.data as BaseModel;    
      if (basedata.sucesso) {
        var data = basedata.data as SearchModel;
        if ( getShowLogs() === 'on') {console.log(data);}
        return data;
      }

      console.error(basedata.mensagem);
      throw new Error(basedata.mensagem);
    })
    .catch(error => {                 
      console.error(error);
      throw new Error(error.response.status);
    }          
  );
};

export const searchDocMultiApi = async (libraries: LibrariesListModel[], textSearch: string, token: string): Promise<LibrariesListModel[]> => {
  const headers = token !== "" ? {headers: {Authorization: `Bearer ${token}`}} : {headers: {Authorization: `none`}};

  await Promise.all(libraries.map(library => {
    let url = `${baseUrl}/search/${library.alias}/${textSearch}`;

    return axios.get(url, headers)
      .then(response => {
        var rowData = response.data as BaseModel;
        library.ocorrencias = rowData.data.pagesCount as number;
        if ( getShowLogs() === 'on') {console.log(library.alias, " - ", library.ocorrencias);}
      })
      .catch(error => {
        library.ocorrencias = 0;
        console.error('API call failed for library:', library.alias, error);
      });
  }));

  return libraries;
};