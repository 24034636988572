import axios from 'axios';
import { Login, LoginResponse, PasswordChangeModel, PasswordResetCodeModel } from  '../models/loginModel';
import { showMessage } from '../languages/_showmessages';
import { getDocReaderAdminAPI } from '../services/appSettings';
import { BaseModel } from '../models/baseModel';

const baseUrl = getDocReaderAdminAPI();
const url = new URL(`${baseUrl}`);

export const loginApi = async (username: string, password: string): Promise<Login> => {
  if (username === '' || password === '') {
    return { mensagem: showMessage('LoginIncorreto'), sucesso: false, token: "" } as Login;
  }

  const result: Login = await axios.post<LoginResponse>(url.toString() + "/login", { username, password })
    .then(response => {
      const data = response.data;

      const rowData: Login = {
        sucesso: data.sucesso,
        mensagem: data.mensagem,
        token: data.data.token,
        username: data.data.username
      };
      if (data.sucesso) {
        return rowData;
      }
      return rowData;
    })
    .catch(error => {
      console.error(error);

      if (error.response && error.response.status === 422) {
        return { mensagem: showMessage('LoginIncorreto'), sucesso: false, token: "" } as Login;
      }
      else if (error.response && error.response.status === 404) {
        return { mensagem: showMessage('PaginaNaoencontrada'), sucesso: false, token: "" } as Login;
      }

      return { mensagem: showMessage('ErroDesconhecido'), sucesso: false, token: "" } as Login;
    }
    );
  return result;
};

export const passwordResetCodeApi = async (username: string): Promise<BaseModel> => {
  if (username === '') {
    return {  mensagem: showMessage('Invaliduser'), sucesso: false } as BaseModel;
  }

  try
  {
    const response = await fetch(url.toString() + "/login/passwordresetcode/" + username, { method: 'POST' });
  }
  catch (error) {
    return {  mensagem: error, sucesso: false } as BaseModel;
  }
  
  return {  mensagem: '', sucesso: true } as BaseModel;
}

// export const passwordResetCodeApi = async (email: string, recaptcharesponse: string): Promise<BaseModel> => {
//   if (email === '' || recaptcharesponse === '') {
//     return {  mensagem: showMessage('invalidparameters'), sucesso: false } as BaseModel;
//   }

//   const result: BaseModel = await axios.post<PasswordResetCodeModel>(url.toString() + "/login/passwordresetcode", { email, recaptcharesponse })
//   .then(response => {
//       return {  mensagem: '', sucesso: true } as BaseModel;;
//     })
//     .catch(error => {
//       console.error(error);
//       return { mensagem: showMessage('ErroDesconhecido'), sucesso: false } as BaseModel;
//     }
//   );

//   return {  mensagem: '', sucesso: true } as BaseModel;;
// };

export const passwordChangeApi = async (username: string, code: string, oldpassword: string, newpassword: string): Promise<BaseModel> => {
  if (username === '' || newpassword === '') {
    return { mensagem: showMessage('invalidparameters'), sucesso: false } as BaseModel;
  }

  const result: BaseModel = await axios.post<PasswordChangeModel>(url.toString() + "/login/passwordchange", { username, code, oldpassword, newpassword })
    .then(response => {
      return { mensagem: showMessage('PasswordChanged'), sucesso: true } as BaseModel;
    })
    .catch(error => {
      return { mensagem: error, sucesso: false } as BaseModel;
    }
    );
  return result;
};

export const isvalidtokenApi = async (token: string): Promise<boolean> => {  
  const result: boolean = await axios.get(url.toString() + "/login/isvalidtoken",  {headers: {Authorization: `Bearer ${token}`}})
    .then(response => {
      return true;
    })
    .catch(error => {
      return false;
    }
  );

  return result;
};