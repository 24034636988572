import { ExportFoldersModel } from '../models/exportPdfModel';  
import { getDocExportAPI, getShowLogs } from '../services/appSettings';

export interface exportPdfResult {
  id: string;
  mensagem?: string
}

const baseUrl = getDocExportAPI();  

export const exportPdf = async ( library: string, quality: string, folders: ExportFoldersModel[], PDFComplianceLevel: string, token: string) : Promise<exportPdfResult> => {
  let result = {} as exportPdfResult;
  try {   
    const url = new URL(`${baseUrl}/DocumentsExport/create/${library}`);
    const headers = token !== "" ? { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`} : { 'Content-Type': 'application/json', Authorization: `none`};

    const response = await fetch(url.toString(), { method: 'POST',
      headers: headers,
      body: JSON.stringify({jpgQuality : quality, PDFComplianceLevel : PDFComplianceLevel, folders: folders })   
    });

    if (!response.ok) {
      result = {id: '', mensagem: 'PDF create error (001) - ' + response.statusText}  as exportPdfResult
      console.error(result.mensagem);
      return result;
    }      

    const id = await response.text(); 
    result = {id: id, mensagem: ''} as exportPdfResult;
    if ( getShowLogs() === 'on') { console.log(result) }
  }
  catch (error) {
    result = {id: '', mensagem: 'PDF create error (002) - ' + error}  as exportPdfResult
    console.error(result.mensagem);
  }
  
  return result;
};

export const exportPdfStatus = async ( id: string) : Promise<string> => {
  var result = "";
  try {
    const url = new URL(`${baseUrl}/DocumentsExport/status/${id}`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });

    if (!response.ok) {
      console.error('Get export status error - ' + response.statusText); 
      return result;
    }      

    result = await response.text(); 
    if ( getShowLogs() === 'on') { console.log(result) }
  } 
  catch (error) {

  }

  return result;
};
   
export const exportPdfDownloadUrl = (id: string) : string => {
  const url = new URL(`${baseUrl}/DocumentsExport/download/${id}`);   
  return url.toString();
};