import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef } from "react-zoom-pan-pinch";
import { useControlContext } from '../context/ControlContext';
import React, { useState, useEffect, useRef } from 'react';
import { pathBaseMount } from '../services/shared';
import { useFolderContext } from '../context/FolderContext';
import { ZoomBox } from './ZoomBox';
import { isMobile } from 'react-device-detect';
import { View, StyleSheet } from 'react-native';
import { useSearchContext } from "../context/SearchContext";
import { findMatchsPositions } from '../services/shared'; 
import { usePageContext } from "../context/PageContext";
import { ImageModel } from "../models/imageModel";
import { pointsModel } from "../models/pointsModel";

interface Props {
    image?: ImageModel;
}

const styles = StyleSheet.create({
  ellipse: {
    borderRadius: 50,
    position: "absolute",
    backgroundColor: "rgba(0, 255, 0, 0.4)",
    width: 25, 
    height: 25,
    transform: [{scaleX: 2}]
  }
});

const DocumentDraw: React.FC<Props> = ({ image }) => {
    const currentStyleX = { width: '100vw', height: 'auto' };
    const grabCursor = {cursor: 'grab'}
    const zoomCursor = {cursor: 'zoom-in'}
    const imageref = React.useRef(null);
    const zoomPanPinchRefs = useRef<(ReactZoomPanPinchContentRef | null)[]>([]);

//    const [ floatButtonVisible, setFloatButtonVisible ] = useState<boolean>(true);
    const [ floatButtonStyle, setFloatButtonStyle ] = useState({ opacity: 1 });
    const { Folder } = useFolderContext();
    const { Page } = usePageContext();
    const { setCommandControlContext } = useControlContext();
    const [ parentDiv ] = React.useState<string>(`'documentodiv'`);
    const [ componentDiv ] = React.useState<string>(`'componentdiv'`);
    const [ matchsPoints, setMatchsPoints ] = useState<pointsModel[]>([]);
    const { Search } = useSearchContext();
    
    const onMouseEnter = (e: any) => {
      setFloatButtonStyle({ opacity: 1 });
    }

    const onMouseLeave = (e: any) => {      
      setFloatButtonStyle({ opacity: 0 });
    }

    const onClickPrevious = () => {
      setCommandControlContext({ pagePrevious: true });
    }
  
    const onClickNext = () => {      
      setCommandControlContext({ pageNext: true });
    }
         
    // Coloca as bolinhas verdes nas ocorrências 
    useEffect(() => {
      setMatchsPoints([]);

      if (image === null || image === undefined ) return;
      if (Search === null || Search === undefined) return;
      
      var squareSize = Math.sqrt(((image.height || 0) * (image.width || 0) / 65000));
      var squareSizeOriginal = Math.sqrt(((image.heightOriginal || 0) * (image.widthOriginal || 0) / 65000));
      var squarePerWidth = Math.trunc((image.widthOriginal || 0) / squareSizeOriginal);

      let matchspositions = findMatchsPositions(Search?.matchs, Folder?.Id || 0, Page || 0);

      const points: React.SetStateAction<pointsModel[]> = ([]);      
      setTimeout(() => {
        let scaleX = (document.getElementById('imgDraw')?.clientWidth || 1) / (image.width || 1);
        let scaleY = (document.getElementById('imgDraw')?.clientHeight || 1) / (image.height || 1);
        matchspositions?.forEach(match => { 
          if (match != 0 ) {
            let point = { X: ((Math.trunc(match % squarePerWidth) * squareSize - (squareSize / 2)) * scaleX), 
                          Y: ((Math.trunc((match / squarePerWidth) + 1) * squareSize - (squareSize / 2)) * scaleY)};

            point.X -= styles.ellipse.width / 2;
            point.Y -= styles.ellipse.height / 2;
            points.push(point);
          }
        });

        setMatchsPoints(points);
      }, 100);
      
    }, [image]);

    // zoom-pan-pinch
    const [doubleStep, setDoubleStep] = useState(5);
    const [scale, setScale] = useState(1);

    // Força a centralização da imagem, 
    useEffect(() => {
      if (image === null || image === undefined ) return;

      if (scale === 1) { 
        setTimeout(() => {
          zoomPanPinchRefs.current[0]?.centerView(1);
        }, 50);
      }
    }, [image]);

    function handleTransform(e: any) {
      setTimeout(() => {
        setDoubleStep((e.state.scale === 1) ? 8 : -8) ;
        setScale(e.state.scale);
      }, 100);
    }

    let touchStartX: number | null = null;
    
    const handleTouchStart = (e: any) => {
        touchStartX = e.touches[0].clientX;        
    };

    const handleTouchEnd = (e: any) => {
        if (touchStartX === null) return;
 
        if (scale !== 1) return;

        const touchEndX = e.changedTouches[0].clientX;
        const difference = touchStartX - touchEndX;

        if (difference > 50) {
            onClickNext();
        } 
        else if (difference < -50) {
            onClickPrevious();
        }

        touchStartX = null;        
    };
    
    return (
      <>
        {isMobile ? 
          <>
            <TransformWrapper centerOnInit={true} disablePadding={true} wheel={{wheelDisabled: false}} panning={{wheelPanning: true}}
                              doubleClick={{mode: "zoomIn", step: doubleStep}} onTransformed={(e) => {handleTransform(e)}} 
                              onZoom={(e) => setScale(e.state.scale)} velocityAnimation={{disabled: true}} 
                              ref={(ref) => { zoomPanPinchRefs.current[0] = ref; }}>

              <TransformComponent>
                <div id={componentDiv} className='componentDiv'>
                  <div id={parentDiv} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className='parentDiv2'>
                    {image?.base64 ? 
                      <>
                        {matchsPoints?.map((point, index) => {return (<View key={index} style={[styles.ellipse, {top: (point.Y), left: (point.X)}]} />)})}
                        <img ref={imageref} className='imgDocument' id='imgDraw' style={currentStyleX} src={image?.base64 || undefined} alt='Document' />
                      </>
                    :
                      <></> 
                    }
                  </div>
                </div>
              </TransformComponent>
            </TransformWrapper>
          </>
          :            
            <div id="mainimgdiv" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="componentDiv"> 
              <ZoomBox imgDrawId='imgDraw' refs={zoomPanPinchRefs} imgUrl={image?.base64 || ''} /> 

              <div className='backButtonNavDash' style={floatButtonStyle} onClick={onClickPrevious}></div>
              {/* {floatButtonVisible ? <div className='backButtonNavDash' style={floatButtonStyle} onClick={onClickPrevious}></div> : <></>} */}

              {/* <TransformWrapper centerOnInit={true} disablePadding={true} wheel={{ wheelDisabled: true}} 
                                panning={{ wheelPanning: true }} doubleClick={{step: doubleStep}} 
                                onTransformed={(e) => {handleTransform(e)}}
                                onPanningStop={(e) => {setImgState({x: e.state.positionX, y: e.state.positionY, scale: e.state.scale})}} */}

              <TransformWrapper centerOnInit={true} disablePadding={true} wheel={{ wheelDisabled: true}} panning={{ wheelPanning: true }} 
                                doubleClick={{step: doubleStep}} onTransformed={(e) => {handleTransform(e)}} 
                                onZoom={(e) => setScale(e.state.scale)}
                                ref={(ref) => { zoomPanPinchRefs.current[0] = ref; }}>
              
                  {() => (
                    <TransformComponent>
                      <div style={scale === 1 ? zoomCursor : grabCursor}>
                        {image?.base64 ? 
                          <>
                            {matchsPoints?.map((point, index) => {return (<View key={index} style={[styles.ellipse, {top: point.Y, left: point.X}]} />)})}
                            <img id='imgDraw' ref={imageref} src={image?.base64 || undefined} className="imgDraw" alt='Document' />
                          </>
                        :
                          <></>
                        }
                      </div>
                    </TransformComponent>
                    )}
              </TransformWrapper>

              <div className='nextButtonNavDash'  style={floatButtonStyle} onClick={onClickNext}></div>
              {/* {floatButtonVisible ? <div className='nextButtonNavDash'  style={floatButtonStyle} onClick={onClickNext}></div> : <></>} */}
          </div>
        }
      </>
    )
}
 
export default DocumentDraw;